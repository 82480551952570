 
/**
 * Checks if a given key belongs to the array of rich field names.
 *
 * @param {string} key - The key to be checked.
 * @param {array} arrayOfString - Not used in the function (consider removing).
 * @return {boolean} True if the key is found in the rich field names, false otherwise.
 */
export function checkForMatch(key,arrayOfString) {
    return arrayOfString.includes(key)
}